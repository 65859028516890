<template>
  <div>
    <el-dialog :visible.sync="dialog" title="商品信息导入" append-to-body :close-on-click-modal="false" :before-close="cancel" @closed="doCancel" :show-close="!importing" width="1080px">
      <div :class="{layout_disabled: importing}">
        <div class="h">
          <el-input v-model="query.code" :maxlength="50" placeholder="输入ERP编码搜索" style="width: 180px;" />&nbsp;
          <el-input v-model="query.name" :maxlength="20" placeholder="输入商品名称搜索" style="width: 180px;" />&nbsp;
          <el-input v-model="query.spec" :maxlength="50" placeholder="输入规格搜索" style="width: 180px;" />&nbsp;
          <el-date-picker v-model="query.dateRange" type="daterange" style="width: 230px;" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" />&nbsp;
          <el-button size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
        </div>
        <div class="h">
          <div class="flex" style="margin-right: 20px;">
            <div class="padding-20-0 h c">
              <b>商品列表</b>
            </div>
            <el-table ref="skus" :data="data" row-key="id" border v-loading="loading" empty-text="没有搜索到商品" @selection-change="handleSkuSelectionChange">
              <el-table-column type="selection" width="35" reserve-selection />
              <el-table-column prop="erpCode" label="ERP编码" min-width="120" />
              <el-table-column prop="name" label="商品名称" min-width="240" />
              <el-table-column prop="specs" label="规格" min-width="120" :formatter="$goodsSpecConvert" />
            </el-table>
          </div>
          <div>
            <div class="padding-20-0">
              <b>导入选项</b>
            </div>
            <el-form ref="form" :model="setting" :rules="rules" label-position="top" hide-required-asterisk v-if="dialog">
              <el-form-item prop="brandId" label="归入品牌">
                <quick-select class="flex" v-model="setting.brandId" url="api/brand" filterable placeholder="请选择品牌" />
              </el-form-item>
              <el-form-item prop="categoryId" label="归入分类">
                <quick-cascader class="flex" v-model="setting.categoryId" url="api/category/tree" filterable placeholder="请选择分类" expand-trigger="hover" />
              </el-form-item>
              <el-form-item prop="seriesId" label="归入系列">
                <quick-cascader class="flex" v-model="setting.seriesId" url="api/series/tree" :params="{brandId:setting.brandId,enabled: true}" checkStrictly filterable placeholder="请选择系列" expand-trigger="hover" />
              </el-form-item>
              <el-form-item label="参与返利">
                <el-checkbox v-model="setting.joinRebate">允许导入的商品参与返利</el-checkbox>
              </el-form-item>
              <el-form-item label="交货周期" prop="deliveryCycle" >
                <div class="h c">
                  <el-input-number type="number" v-model="setting.deliveryCycle" :min="0" :max="365" :step="1" :precision="0" controls-position="right" class="flex" />
                  <span>&nbsp;天</span>
                </div>
              </el-form-item>
              <el-form-item label="导入方式">
                <el-select class="flex" v-model="setting.opType" filterable>
                  <el-option value="insert" label="仅导入新的商品信息" />
                  <el-option value="update" label="导入并更新商品信息" />
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div slot="footer" class="h c">
        <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes,jumper" @size-change="sizeChange" @current-change="pageChange" />
        <div class="flex"></div>
        <el-button type="primary" :loading="importing" @click="handleImport">开始导入</el-button>
        <el-button type="text" @click="dialog = false">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="result.show" title="导入结果" append-to-body :close-on-click-modal="false" width="640px">
      <div v-if="result.detail">
        <div class="padding-05-0">
          本次总共导入&nbsp;
          <b>{{result.detail.total}}</b>&nbsp;个SKU
        </div>
        <div class="padding-05-0">
          其中成功导入&nbsp;
          <b class="fc-s">{{result.detail.success}}</b>&nbsp;个，跳过了&nbsp;
          <b class="fc-e">{{result.detail.skip.length}}</b>&nbsp;个。
        </div>
      </div>
      <div slot="footer">
        <el-button type="primary" @click="result.show = false; dialog = false;">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import request from "@/utils/request";

export default {
  mixins: [initData],
  data() {
    return {
      dialog: false,
      importing: false,
      current: null,
      selection: [],
      skus: [],
      query: {
        code: "",
        name: "",
        spec: "",
        dateRange: null
      },
      setting: {
        brandId: null,
        categoryId: null,
        seriesId: null,
        opType: "insert",
        joinRebate: true,
        deliveryCycle: 7,
      },
      rules: {
        brandId: [{ required: true, message: "请选择品牌" }],
        categoryId: [{ required: true, message: "请选择分类" }],
        seriesId: [{ required: true, message: "请选择系统" }]
      },
      result: {
        show: false,
        detail: null
      },
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近两年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 2);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      }
    };
  },
  methods: {
    beforeInit() {
      this.url = "api/goodsByErp";
      this.params = {
        code: this.query.code,
        name: this.query.name,
        specs: this.query.spec
      };
      if (this.query.dateRange && this.query.dateRange.length === 2) {
        this.params.beginTime = this.query.dateRange[0];
        this.params.endTime = this.query.dateRange[1];
      }
      return true;
    },
    afterLoad(res) {
      let goods = [];
      res.forEach(o => {
        let co = JSON.parse(JSON.stringify(o));
        delete co.skus;
        (o.skus || []).forEach(s => {
          s.goods = co;
          goods.push(s);
        });
      });
      return goods;
    },
    cancel(done) {
      if (this.selection && this.selection.length) {
        this.$confirm("您确定要放弃本次导入吗？", "操作确认", {
          type: "warning"
        }).then(_ => {
          done && done();
        });
      } else {
        done && done();
      }
    },
    doCancel() {
      this.page = 0;
      this.data = [];
      this.skus = [];
      this.setting.seriesId = null;
      this.setting.categoryId = null;
      this.setting.brandId = null;
      this.setting.opType = "insert";
      this.$refs.skus && this.$refs.skus.clearSelection();
      this.selection = [];
      this.current = null;
      this.query.code = "";
      this.query.name = "";
      if (typeof done === "function") done();
    },
    handleSkuSelectionChange(sels) {
      this.selection = sels;
    },
    handleImport() {
      if (this.selection && this.selection.length) {
        this.$refs.form &&
          this.$refs.form.validate().then(_ => {
            this.$alert(
              "商品导入过程可能比较漫长，请您耐心等候。",
              "导入提示"
            ).then(_ => {
              let url =
                "api/goods/batchSave?opType=" +
                this.setting.opType +
                "&joinRebate=" +
                this.setting.joinRebate;
              if (this.setting.seriesId) {
                url = url + "&seriesId=" + this.setting.seriesId;
              }
              if (this.setting.categoryId) {
                url = url + "&categoryId=" + this.setting.categoryId;
              }
              if (this.setting.brandId) {
                url = url + "&brandId=" + this.setting.brandId;
              }
              if (this.setting.deliveryCycle) {
                url = url + "&deliveryCycle=" + this.setting.deliveryCycle;
              }
              this.importing = true;
              request({
                url: url,
                method: "post",
                data: this.selection
              })
                .then(res => {
                  if (res) {
                    this.result.show = true;
                    this.result.detail = res;
                    this.$parent.init();
                  }
                })
                .finally(_ => {
                  this.importing = false;
                });
            });
          });
      } else {
        this.$alert("请选择要导入的SKU", "系统提示", {
          type: "warning"
        });
      }
    },
    show() {
      this.dialog = true;
      this.toQuery();
    }
  }
};
</script>