<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" title="统一零售价导入">
    <div class="head-container">
      <el-upload class="filter-item" ref="upload" :action="uploadUrl" :headers="uploadHeaders" :before-upload="handleBeforeUpload" :on-success="handleUploadSuccess" :on-error="handleUploadError" :show-file-list="false" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
              <el-button type="primary" class="filter-item" icon="el-icon-upload2" :loading="uploading">{{btnName}}</el-button>
            </el-upload>
      <el-button type="primary" @click="downloadTemplate" class="filter-item" icon="el-icon-download" :loading="downloadLoading">模板下载</el-button>
    </div>
    <div class="v" v-if="sucInfo">
      <span>导入结果：共{{sucInfo.count}}条,成功{{sucInfo.sucCount}}条,失败{{sucInfo.errCount}}条</span>&nbsp;          
      <span>错误详情</span>
      <el-table :data="errList" border style="width: 100%;">
        <el-table-column prop="name" min-width="200" show-overflow-tooltip  label="商品名称" />
        <el-table-column prop="erpCode" header-align="center" show-overflow-tooltip align="center" label="ERP编码" />
        <el-table-column prop="retailPrice"  show-overflow-tooltip label="统一零售价(元)" />
        <el-table-column prop="error"   label="错误信息" width="250" show-overflow-tooltip />
      </el-table>
    </div>
  </el-dialog>
</template>
<script>
import config from "@/config";
import { getToken } from "@/utils/auth";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
export default {
  data() {
    return {
      uploading: false,
      uploadUrl: config.hosts.request + "api/goods/sku/retail/import",
      uploadHeaders: {},
      downloadLoading: false,
      dialog: false,
      errList:[],
      sucInfo:null,
      errInfo:null,
    }
  },
  computed:{
    btnName(){ 
      return this.uploading?"导入中":"选择文件";
    }
  },
  methods:{
    handleBeforeUpload() {
      this.uploading = true;
    },
    handleUploadSuccess(res) {      
      this.sucInfo = res;
      this.errList = res.errList;
      this.uploading = false;
      if(res.sucCount && res.sucCount>0){      
        this.$parent.init();      
      }
    },
    handleUploadError(err) {
      console.log(err);
      this.errInfo = err;
      this.uploading = false;
    },    
    downloadTemplate(){
      this.downloadLoading = true;
      download("api/goods/sku/template")
        .then(result => {
          downloadFile(result, "统一零售价模板", "xlsx");
          this.downloadLoading = false;
        })
        .catch(err => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
    resetForm(){
      this.uploadHeaders.Authorization = "Bearer " + getToken();
      this.sucInfo = null;
      this.dialog = true;
    }
  }
}
</script>