<template>
  <div class="app-container">
    <div v-show="!edit.show">
      <div class="head-container">
        <quick-select url="api/brand" v-model="query.brandId" filterable placeholder="品牌" class="filter-item" @change="toQuery" clearable style="width: 150px;" />
        <!-- <quick-cascader class="filter-item" v-model="query.seriesId" url="api/series/tree" :params="{enabled: true,brandId:query.brandId}" root-key @change="toQuery" check-strictly filterable clearable placeholder="系列" expand-trigger="hover" style="width: 200px;" /> -->
        <quick-cascader class="filter-item" v-model="query.seriesIds" url="api/series/tree" :params="{enabled: true,brandId:query.brandId}" root-key @change="toQuery" filterable clearable placeholder="系列" expand-trigger="hover" style="width: 250px;" multiple checkStrictly collapseTags />
        <quick-cascader class="filter-item" v-model="query.categoryId" url="api/category/tree" @change="toQuery" filterable clearable placeholder="分类" expand-trigger="hover" style="width: 200px;" />
        <el-select v-model="query.onShelf" placeholder="上架状态" style="width:100px" class="filter-item" clearable @change="toQuery">
          <el-option :key="1" label="上架" :value="true"></el-option>
          <el-option :key="2" label="下架" :value="false"></el-option>
        </el-select>
        <el-input v-model="query.keywords" clearable placeholder="商品名称/编码" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
        <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" />
        <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
        <el-button v-permission="['GOODS_ALL', 'GOODS_ADD']" class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="doAdd">新增</el-button>
        <el-button v-permission="['GOODS_ALL', 'GOODS_IMPORT']" class="filter-item" size="mini" type="primary" icon="el-icon-upload2" @click="doImport" v-if="$erp.enable()">导入</el-button>
        <el-button v-permission="['GOODS_ALL', 'GOODS_EXPORT']" class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
        <el-button v-permission="['GOODS_RETAIL_IMPORT']" type="primary" size="mini" @click="uploadRetail" class="filter-item" icon="el-icon-upload2">统一零售价导入</el-button>
        <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
      </div>
      <el-table v-loading="loading" :data="data" row-key="_id" lazy :load="loadSkus" :default-sort="{prop: 'createTime', order: 'descending'}" @sort-change="handleSort">
        <el-table-column prop="name" label="名称" min-width="200px" sortable="custom" show-overflow-tooltip fixed />
        <el-table-column prop="specs" label="规格" min-width="200px" show-overflow-tooltip fixed />
        <el-table-column prop="code" label="商品编码" min-width="155px" sortable="custom" fixed />
        <el-table-column prop="erpCode" label="ERP编码" min-width="155px" sortable="custom" fixed />
        <el-table-column prop="brandName" label="品牌" min-width="100px" sortable="custom" />
        <el-table-column prop="categoryName" label="分类" min-width="150px" sortable="custom" />
        <el-table-column prop="seriesName" label="系列" min-width="150px" sortable="custom" />
        <el-table-column prop="skuShelfNumber" label="状态" width="120px" sortable="custom">
          <template slot-scope="scope">
            <div v-if="scope.row._type !=='sku'">
              <span>{{ scope.row.onShelf?'上架:'+(scope.row.skuShelfNumber?scope.row.skuShelfNumber:0):'未上架' }}</span>&nbsp;
              <span v-if="scope.row.onShelf">{{'下架:'+ (scope.row.skuTotal- scope.row.skuShelfNumber) }}</span>
            </div>
            <div v-else>
              <span>{{scope.row.onShelf?'已上架':'未上架'}}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="createAt" label="创建日期" width="120px" :formatter="v=>{return v.createAt?new Date(v.createAt).format('yyyy/MM/dd'):''}" sortable="custom" />
        <el-table-column label="上下架" width="70" fixed="right">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.onShelf" size="mini" :disabled="user.entId !== scope.row.entId" @change="changeOnshelf(scope.row, $event)" v-if="checkPermission(['GOODS_ONSHELF'])" />            
            <i class="fa fa-check" v-else-if="scope.row.onShelf"></i>          
          </template>
        </el-table-column>
        <el-table-column width="110" fixed="right">
          <div class="row-commands" slot-scope="scope" v-if="scope.row._type !== 'sku'">
            <!-- <el-button size="mini" type="text" @click="doEdit(scope.row)" v-permission="['GOODS_ALL', 'GOODS_EDIT']">{{user.entId === scope.row.entId ? "编辑" : "查看"}}</el-button> -->
            <!-- <el-button size="mini" type="text" @click="doEdit(scope.row)">{{user.entId === scope.row.entId ? "编辑" : "查看"}}</el-button> -->
            <template v-if="user.entId===scope.row.entId">
              <el-button size="mini" type="text" @click="doEdit(scope.row)" v-if="checkPermission(['GOODS_ALL','GOODS_EDIT'])">编辑</el-button>
              <el-button size="mini" type="text" @click="doEdit(scope.row)" v-else>查看</el-button>
            </template>

            <el-popover :ref="scope.row.id" placement="top" width="180" v-if="user.entId === scope.row.entId" v-permission="['GOODS_ALL', 'GOODS_DEL']">
              <p>确定删除本条数据吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
                <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
              </div>
              <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
            </el-popover>
          </div>
        </el-table-column>
      </el-table>
      <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    </div>

    <editor ref="form" :id.sync="edit.current" v-if="edit.show" @cancel="handleEditCancel" />
    <importer ref="importer" />
    <retailForm ref="retailForm" />
  </div>
</template>
<script>
import checkPermission from "@/utils/permission";
import { parseTime } from "@/utils/index";
import initData from "@/mixins/initData";
import { del, getSkus, updateOnShelf } from "@/api/goods";
import { mapGetters } from "vuex";
import editor from "./editor";
import importer from "./import";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import retailForm from "../maintain/retail-import";

export default {
  components: { editor, importer, retailForm },
  mixins: [initData],
  data() {
    return {
      downloadLoading: false,
      delLoading: false,
      loding: false,
      edit: {
        show: false,
        current: null,
      },
      //时间查询
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      query: {
        time: null,
        value: null,
        enabled: null,
        date: null,
        dateRange: null,
        brandId: null,
        seriesId: null,
        categoryId: null,
        keywords: null,
      },
      sort: "createAt,desc",
      enabledTypeOptions: [
        { key: "0", display_name: "下架" },
        { key: "1", display_name: "上架" },
        { key: "2", display_name: "待审批" },
      ],
      categories: [],
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    parseTime,
    checkPermission,
    handleEditCancel() {
      this.edit.show = false;
      this.toQuery();
    },
    beforeInit() {
      this.url = "api/goods";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.createAtBeg = query.dateRange[0];
        query.createAtEnd = query.dateRange[1];
      }
      delete query.dateRange;
      this.params = Object.assign(
        {
          page: this.page,
          size: this.size,
          sort: this.sort,
          salesmanId: this.user.id,
        },
        query
      );
      // this.params = Object.assign({ sort: this.sort }, this.query);
      return true;
    },
    uploadRetail() {
      this.$refs.retailForm && this.$refs.retailForm.resetForm();
    },
    changeOnshelf(v, d) {
      updateOnShelf(v.id, { onShelf: d })
        .then((_) => {
          this.init();
          this.$notify({
            title: "操作成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          v.onShelf = !d;
        });
    },
    afterLoad(res) {
      res.forEach((o) => {
        o._id = this.$uuid();
        o.hasChildren = true;
      });
    },
    handleSort(sort) {
      if (sort.order == null) this.sort = "";
      else
        this.sort =
          sort.prop + "," + (sort.order === "ascending" ? "asc" : "desc");
      this.toQuery();
    },
    loadSkus(row, node, resolve) {
      if (row) {
        getSkus(row.id).then((res) => {
          let ds = [];
          res.forEach((o) => {
            if (o.enable) {
              o._id = this.$uuid();
              delete o.brandName;
              delete o.seriesName;
              delete o.categoryName;
              o._type = "sku";
              o.specs = this.$goodsSpecConvert(o.specs);
              if (!!!o.name) o.name = row.name;
              ds.push(o);
            }
          });
          resolve(ds);
        });
      } else {
        resolve([]);
      }
    },
    doAdd() {
      this.edit.current = null;
      this.edit.show = true;
    },
    doEdit(data) {
      this.edit.current = data.id;
      this.edit.show = true;
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
        });
    },
    doImport() {
      this.$refs.importer && this.$refs.importer.show();
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("api/goods/sku/download", this.params)
        .then((result) => {
          downloadFile(result, "商品信息", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
  },
};
</script>